import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { FloatingLabel, Form, Container, Row, Col } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

import { EdianAPI } from "../../Api/EdianAPI";

import { updateSession, clearSession } from "../../Redux/sessionSlice";

import "./ContactPage.css";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      showLoader: false,
      showError: null,
      showMessage: null,
    };

    this._edianAPI = new EdianAPI();
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  onValueChange(e) {
    console.log(e.target);
    let changeSet = {};
    changeSet[e.target.name] = e.target.value;
    this.props.updateSession(changeSet);
  }

  contactPost() {
    this.setState({
      showLoader: true,
      showError: null,
      showMessage: null,
    });

    this._edianAPI
      .contactPost(this.props.session)

      .then((rc) => {
        this.setState({
          showLoader: false,
          showError: null,
          showMessage: "MESSAGE SENT!",
        });
      })

      .catch((rc) => {
        this.setState({ showLoader: false });
        this.setState({
          showLoader: false,
          showError: "SERVICE INTERRUPTED, PLEASE EMAIL info@edian.io ",
          showMessage: null,
        });
      });
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <div className="Page">
        <div className="ContactPageBanner">
          <p>CONTACT</p>
        </div>
        {this.state.showMessage && (
          <div className="PageBlock">
            <p className="PageBlockSuccess">{this.state.showMessage}</p>
          </div>
        )}
        {this.state.showError && (
          <div className="PageBlock">
            <p className="PageBlockError">{this.state.showError}</p>
          </div>
        )}
        {this.state.showLoader == false && (
          <div className="PageBlock">
            <FloatingLabel
              controlId="floatingInput"
              label="Name"
              className="mb-3"
            >
              <Form.Control
                value={this.props.session.name}
                onChange={(e) => {
                  this.onValueChange(e);
                }}
                name="name"
                type="text"
                placeholder="Please enter your Name"
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingInput"
              label="Email address"
              className="mb-3"
            >
              <Form.Control
                value={this.props.session.email}
                onChange={(e) => {
                  this.onValueChange(e);
                }}
                name="email"
                type="email"
                placeholder="name@example.com"
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingInput"
              label="Linkedin Profile"
              className="mb-3"
            >
              <Form.Control
                value={this.props.session.linkedin_profile}
                onChange={(e) => {
                  this.onValueChange(e);
                }}
                name="linkedin_profile"
                type="text"
                placeholder="Linkedin Profile"
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingInput"
              label="Company Name"
              className="mb-3"
            >
              <Form.Control
                value={this.props.session.company_name}
                onChange={(e) => {
                  this.onValueChange(e);
                }}
                name="company_name"
                type="text"
                placeholder="Company Name"
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingInput"
              label="Company Size"
              className="mb-3"
            >
              <Form.Control
                value={this.props.session.company_size}
                onChange={(e) => {
                  this.onValueChange(e);
                }}
                name="company_size"
                type="text"
                placeholder="Company Size"
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingInput"
              label="Any Notes"
              className="mb-3"
            >
              <Form.Control
                value={this.props.session.notes}
                onChange={(e) => {
                  this.onValueChange(e);
                }}
                name="notes"
                type="textarea"
                placeholder="Any additional notes"
              />
            </FloatingLabel>

            <a
              className="PrimaryButton"
              onClick={() => {
                this.redirect("/");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-house-door-fill"
                viewBox="0 0 16 16"
              >
                <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5" />
              </svg>
            </a>
            <a
              className="PrimaryButton"
              onClick={() => {
                this.contactPost();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-telegram"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
              </svg>
            </a>
          </div>
        )
        }
        {
          this.state.showLoader == true && (
            <div className="PageBlock">
              <h1>...</h1>
              <p>we will get back to you shortly</p>
              <Spinner />
            </div>
          )
        }
        <div className="PageBlock">
          <p>© Edian Software 2024. All rights reserved</p>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = () => ({
  updateSession,
  clearSession,
});

export default connect(mapStateToProps, mapDispatchToProps())(ContactPage);
