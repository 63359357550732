import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { FloatingLabel, Form, Container, Row, Col } from "react-bootstrap";

import { updateSession, clearSession } from "../../Redux/sessionSlice";

import "./IdeatePage.css";

class IdeatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    };
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <div className="Page LandingPage">
        <div className="LandingPageBanner">
        </div>
        <div className="PageBlock">
          <h1>IDEATE</h1>
          <p>below are the key concepts to consider, because of the open ended nature of this phase, it is billed per hour, no time limit is imposed, and for your peace of mind, timesheets are made available daily</p>
        </div>
        <div className="PageBlock">
          <h1>UX</h1>
          <p>all figma designs and digital assets provided from shared space</p>
          <p>
            <img
              className="Screenshot"
              src="https://edian.io/assets/ideate_figma.png"
            />
          </p>
          <p>
            <a
              href="https://www.figma.com/resource-library/design-basics/"
              target="_blank"
              className="PrimaryButton"
            >
              Learn More
            </a>
          </p>
        </div>
        <div className="PageBlock">
          <h1>DATA MODELS AND ACCESS PATTERNS</h1>
          <p>a modern data strategy is required for efficiency and scalability, this will be shared via miro</p>
          <p>
            <img
              className="Screenshot"
              src="https://edian.io/assets/ideate_data.jpg"
            />
          </p>
          <p>
            <a
              href="https://aws.amazon.com/data/datastrategy/"
              target="_blank"
              className="PrimaryButton"
            >
              Learn More
            </a>
          </p>
        </div>

        <div className="PageBlock">
          <h1>CAPABILITY BUILDING STRATEGY</h1>
          <p>new systems and processes require new ways of thinking, we can help you through this process</p>
          <p>
            <img
              className="Screenshot"
              src="https://edian.io/assets/ideate_people.jpg"
            />
          </p>
        </div>
        <div className="PageBlock">
          <h1>PROCESS FLOWS</h1>
          <p>connecting the dots, a living document on a platform of your choice</p>
          <p>
            <img
              className="Screenshot"
              src="https://edian.io/assets/ideate_process.jpg"
            />
          </p>
          <p>
            <a
              href="https://www.figma.com/templates/process-map-template/"
              target="_blank"
              className="PrimaryButton"
            >
              Learn More
            </a>
          </p>
        </div>
        <div className="PageBlock">
          <h1>ARCHITECTURE DIAGRAMS</h1>
          <p>high and low level architecture diagrams shared via miro</p>
          <p>
            <img
              className="Screenshot"
              src="https://edian.io/assets/ideate_architecture.png"
            />
          </p>
          <p>
            <a
              href="https://aws.amazon.com/what-is/architecture-diagramming/"
              target="_blank"
              className="PrimaryButton"
            >
              Learn More
            </a>
          </p>
        </div>
        <div className="PageBlock">
          <h1>CLOUDFORMATION TEMPLATES</h1>
          <p>shared via version control system of your choice</p>
          <p>
            <img
              className="Screenshot"
              src="https://edian.io/assets/ideate_code.jpg"
            />
          </p>
          <p>
            <a
              href="https://aws.amazon.com/what-is/architecture-diagramming/"
              target="_blank"
              className="PrimaryButton"
            >
              Learn More
            </a>
          </p>
        </div>
        <div className="">
          <div
            className="LandingPageMethod"
            onClick={() => {
              this.redirect("/");
            }}
          >
            <h1>HOME</h1>
            <p>home page</p>
          </div>

          <div
            className="LandingPageMethod"
            onClick={() => {
              this.redirect("/create");
            }}
          >
            <h1>CREATE</h1>
            <p>click to learn more</p>
          </div>
          <div
            className="LandingPageMethod"
            onClick={() => {
              this.redirect("/release");
            }}
          >
            <h1>RELEASE</h1>
            <p>click to learn more</p>
          </div>
        </div>
        <div className="PageBlock">
          <p>
            contact us on <a href='mailto:info@edian.io'>info@edian.io</a>
          </p>
          <p>© Edian Software 2024. All rights reserved</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = () => ({
  updateSession,
  clearSession,
});

export default connect(mapStateToProps, mapDispatchToProps())(IdeatePage);
