import axios from "axios";

export class EdianAPI {
  _url = "https://0pdvv13410.execute-api.af-south-1.amazonaws.com/Prod";

  constructor() {
    this.state = {
      redirect: null,
    };
  }

  contactPost(contactPayload) {
    let url = `${this._url}/contact`;
    console.log(`-- post to ${url}`);

    let payload = {
      name: "",
      email: "",
      linkedin_profile: "",
      company_name: "",
      company_size: "",
      notes: "",
    };

    for (const [key, value] of Object.entries(contactPayload)) {
      payload[key] = value;
    }

    console.log(payload);

    return axios.post(url, payload);
  }
}
