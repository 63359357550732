import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import LandingPage from "./Pages/LandingPage/LandingPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import IdeatePage from "./Pages/IdeatePage/IdeatePage";
import CreatePage from "./Pages/CreatePage/CreatePage";
import ReleasePage from "./Pages/ReleasePage/ReleasePage";

import "./App.css";

export class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    var self = this;
  }

  render() {
    let session = this.props.session;

    return (
      <div className="App">
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/ideate" element={<IdeatePage />} />
          <Route path="/create" element={<CreatePage />} />
          <Route path="/release" element={<ReleasePage />} />
        </Routes>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps())(App);
