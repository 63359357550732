import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    updateSession: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },

    clearSession: (state, action) => {
      state.sessionId = null;
    },
  },
});

export const { updateSession, clearSession } = sessionSlice.actions;

export default sessionSlice.reducer;
