import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { FloatingLabel, Form, Container, Row, Col } from "react-bootstrap";

import { updateSession, clearSession } from "../../Redux/sessionSlice";

import "./LandingPage.css";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    };
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <div className="Page LandingPage">
        <div className="LandingPageBanner">
          <img src='https://edian.io/assets/logo512.png' />
        </div>

        <div className="PageBlock">
          <h1>edian.io</h1>
          <p>cloud and ai consultancy</p>
        </div>

        <div className="PageBlock">
          <img src="https://edian.io/assets/aws_cpf.png" />
          <img src="https://edian.io/assets/aws_aif.png" />
          <img src="https://edian.io/assets/aws_saa.png" />
          <img src="https://edian.io/assets/aws_da.png" />
          <img src="https://edian.io/assets/aws_mla.png" />
          <img src="https://edian.io/assets/aws_sysaa.png" />
          <img src="https://edian.io/assets/aws_data_associate.png" />
          <img src="https://edian.io/assets/aws_sap.png" />
          <img src="https://edian.io/assets/aws_dep.png" />
          <img src="https://edian.io/assets/aws_mls.png" />
          <img src="https://edian.io/assets/aws_ss.png" />
          <img src="https://edian.io/assets/aws_das.webp" />
          <img src="https://edian.io/assets/aws_database.webp" />
          <img src="https://edian.io/assets/aws_nws.png" />

          <img src="https://edian.io/assets/hashicorp_terraform.png" />
        </div>
        <div className="PageBlock">
          <p>
            contact us on <a href='mailto:info@edian.io'>info@edian.io</a>
          </p>
          <p>© Edian Software 2024. All rights reserved</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = () => ({
  updateSession,
  clearSession,
});

export default connect(mapStateToProps, mapDispatchToProps())(LandingPage);
