import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { FloatingLabel, Form, Container, Row, Col } from "react-bootstrap";

import { updateSession, clearSession } from "../../Redux/sessionSlice";

import "./CreatePage.css";

class CreatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    };
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <div className="Page LandingPage">
        <div className="LandingPageBanner">

        </div>
        <div className="PageBlock">
          <h1>CREATE MVP</h1>
          <p>fixed time. defined at ideation phase</p>
        </div>
        <div className="PageBlock">
          <h1>FIXED BUDGET</h1>
          <p>defined at ideation phase</p>
        </div>

        <div className="PageBlock">
          <h1>OUTCOME</h1>WORKING SOFTWARE
        </div>
        <div className="PageBlock">
          <div
            className="LandingPageMethod"
            onClick={() => {
              this.redirect("/");
            }}
          >
            <h1>HOME</h1>
            <p>home page</p>
          </div>

          <div
            className="LandingPageMethod"
            onClick={() => {
              this.redirect("/ideate");
            }}
          >
            <h1>IDEATE</h1>
            <p>click to learn more</p>
          </div>
          <div
            className="LandingPageMethod"
            onClick={() => {
              this.redirect("/release");
            }}
          >
            <h1>RELEASE</h1>
            <p>click to learn more</p>
          </div>
        </div>


        <div className="PageBlock">
          <p>
            contact us on <a href='mailto:info@edian.io'>info@edian.io</a>
          </p>
          <p>© Edian Software 2024. All rights reserved</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = () => ({
  updateSession,
  clearSession,
});

export default connect(mapStateToProps, mapDispatchToProps())(CreatePage);
